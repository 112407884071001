import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDoc, doc, updateDoc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
// import 'firebase/analytics'
import { getFunctions } from 'firebase/functions'
import moment from 'moment';
// import _ from 'lodash'

const currentYear = moment().format('YYYY')
const prevYear = moment().subtract(1,'year').format('YYYY')

//console.log(currentYear, prevYear)

const firebaseConfig = {
  apiKey: "AIzaSyBjNPRtvVd3wKwXl1BTQYeH3BG8wiJCN3I",
  authDomain: "golf-group.firebaseapp.com",
  databaseURL: "https://golf-group.firebaseio.com",
  projectId: "golf-group",
  storageBucket: "golf-group.appspot.com",
  messagingSenderId: "110220444166",
  appId: "1:110220444166:web:7be902cbbf78432c",
  measurementId: "G-7GGS3HFVFX"
};

// Get a Firestore instance
initializeApp(firebaseConfig)
export const fs = getFirestore()
export const fa = getAuth()
export const ff = getFunctions()
export const roundRef = collection(fs, 'years', currentYear ,'rounds')
export const prevRoundRef = collection(fs, 'years', prevYear, 'rounds')
export const playerRef = collection(fs, 'players')
export const calRef = collection(fs, 'years', currentYear, 'calendar')

//export const fv = require('firebase-admin').firestore.FieldValue

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
// const { Timestamp, GeoPoint } = firebase.firestore
// export { Timestamp, GeoPoint }

// export const deleteRound = (key) => {
//   fs.collection('rounds').doc(moment().format('YYYY')).update({
//     [key]: firebase.firestore.FieldValue.delete()
//   })
//   console.log('round deleted');
// }

// let update = {'One': {
//   hnds: [13,7,3,17,1,11,9,15,5,12,4,18,8,16,2,6,14,10],
//   pars: [4,5,4,3,4,5,4,3,4,4,4,3,4,4,5,4,3,5],
//   in:36,
//   out:36,
//   rating:63.4,
//   slope:115
// }
// }

// updateDoc(doc(fs, 'courses', 'Meadowbrook'),update)



// let batch = fs.batch()
// fs.collection('years').doc('2022').collection('calendar').get().then(cal => {
//   let u = {}
//   cal.docs.forEach(c => {
//     if (!c.data().type) u = {type: 'vegas'}
//     batch.update(fs.collection('years').doc('2022').collection('calendar').doc(c.id), u)
//   })
//   batch.commit()
// })


